import React, { useEffect, useState, useRef } from 'react';

// Canvas Component to be rendered when the section comes into view
const TradeSmarterSection = () => {
  const [isInView, setIsInView] = useState(false); // To track visibility of the section
  const canvasRef = useRef(null); // Ref to reference the canvas element

  // Function to check if the element is in the viewport
  const checkIfInView = () => {
    const section = document.getElementById('trade-smarter');
    const rect = section.getBoundingClientRect();
    return rect.top <= window.innerHeight && rect.bottom >= 0;
  };

  // Scroll event listener to detect if section is in view
  useEffect(() => {
    const handleScroll = () => {
      if (checkIfInView()) {
        setIsInView(true); // Mark the section as in view
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check on initial render too

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to create and animate the canvas when section is in view
  useEffect(() => {
    if (isInView && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      let x = 0;

      const animateCanvas = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas
        ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
        ctx.fillRect(x, 50, 100, 100); // Draw a moving rectangle
        x += 1;
        if (x > canvas.width) x = -100; // Reset when reaching the edge
        requestAnimationFrame(animateCanvas); // Continue animation
      };

      animateCanvas();
    }
  }, [isInView]); // Only run when the section comes into view

  return (
    <section id="trade-smarter" className="trade-smarter">
      <h2>Trade Smarter with Our Trading Tools</h2>
      <p>Explore our advanced trading tools to make smarter decisions.</p>
      
      {/* Show the canvas only when the section is in view */}
      {isInView && (
        <div id="canvas-container" style={{ position: 'relative', width: '100%', height: '300px' }}>
          <canvas
            ref={canvasRef}
            width="100%"
            height="100%"
            style={{ display: 'block', borderRadius: '8px' }}
          />
        </div>
      )}
    </section>
  );
};

export default TradeSmarterSection;
