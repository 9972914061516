import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import { Link, redirect, useNavigate } from "react-router-dom";
export default function CommingSoon() {
  const redirect = useNavigate()
  return (
    <>
      <ScrollToTop>
        <section className="section_space commingsoon_bg">
          <div className="container">
            <div className="text-center">
              <div className="my-5">
                <Link to="/" className="navbar-brand">
                  <img
                    src="/assets/images/logo/Tradsify.svg"
                    alt=""
                    className="img-fluid h-auto"
                  />
                </Link>
              </div>
              <div className="">
                <img
                  src="assets/images/commingsoon/coming-soon.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div>
                <p className="font_20 mt-xl-4 mt-3 fw-semibold mb-2">
                  Let's get started with Tradsify
                </p>
                <p className="font_14 text-secondary">
                  We are still working on it. Please check back later.
                </p>
                <button className="btn btn_black me-2 mt-lg-2 mt-0" onClick={() => redirect("/")}>Back To Home</button>
              </div>
            </div>
          </div>
        </section>
      </ScrollToTop>
    </>
  );
}
