import React, { useState } from "react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function HeroSwiper() {
  const cardsData = [
    {
      img: "assets/images/home/fund.webp",
      title: "Funds Growth",
      description:
        "Deposit and trade CFDs to unlock interest of Funds Growth your idle funds like never before!",
    },
    {
      img: "assets/images/home/deposite_bonus.webp",
      title: "Deposit Bonus",
      description:
        "Boost your trades - Double your deposit with our Upto 100% bonus offered for new deposits! Deposit and trade CFDs from your home country, whether you are from Philippines, Thailand, or beyond.",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(1);

  const formatNumber = (number) => {
    return number.toString().padStart(2, "0");
  };

  return (
    <section className="section_space pb-0 hero_banner">
      <div className="container">
        <div className="">
          <div className="hero_card">
            {cardsData.slice(currentSlide - 1, currentSlide).map((card, index) => (
              <div className="row g-xl-5 g-4 align-items-center">
                <div className="col-lg-7 order-lg-1 order-2">
                  <div className="wow animate__animated  animate__fadeInRightBig">
                    <div className="d-md-block d-none">
                      <span id="slide-numbe" className="wow animate__animated  animate__fadeIn">
                        <span className="small_heading popular">
                          {formatNumber(currentSlide)}
                        </span>{" "}
                        <span className="font_18 blue_dull">/ </span>
                        <span className="font_14 blue_dull">
                          {formatNumber(cardsData.length)}
                        </span>
                      </span>
                    </div>
                    <h1 className="sub_heading my-xl-4 my-3 wow animate__animated  animate__fadeInRightBig">{card.title}</h1>
                    <p className="font_18 mb-3 ">{card.description}</p>
                    <button className="btn btn_black text-uppercase mt-2">
                      Learn more
                    </button>
                  </div>
                </div>
                <div className="col-lg-5 order-lg-2 order-1 ">
                  {
                    card.img ?
                      <>
                        <div className="wow animate__animated  animate__fadeInRightBig">
                          <img
                            src={card.img}
                            alt={card.title}
                            className="img-fluid hero_swiper_img wow animate__animated animate__fadeIn"
                          />
                        </div>
                      </>

                      : ""
                  }

                </div>
              </div>
            ))}
            {/* <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={25}
              slidesPerView={1}
              navigation={{
                nextEl: ".swiper-button-hero-next",
                prevEl: ".swiper-button-hero-prev",
              }}
              loop={true}
              autoplay={{
                delay: 8000,
              }}
              onSlideChange={(swiper) => {
                const activeIndex = swiper.realIndex + 1;
                setCurrentSlide(activeIndex);
              }}
            >
              {cardsData.map((card, index) => (
                <SwiperSlide key={index}>
                  <div className="row g-xl-5 g-4 align-items-center">
                    <div className="col-lg-7 order-lg-1 order-2">
                      <div className="wow animate__animated  animate__fadeInRightBig">
                        <div className="d-md-block d-none">
                          <span id="slide-numbe" className="wow animate__animated  animate__fadeIn">
                            <span className="small_heading popular">
                              {formatNumber(currentSlide)}
                            </span>{" "}
                            <span className="font_18 blue_dull">/ </span>
                            <span className="font_14 blue_dull">
                              {formatNumber(cardsData.length)}
                            </span>
                          </span>
                        </div>
                        <h1 className="sub_heading my-xl-4 my-3 wow animate__animated  animate__fadeInRightBig">{card.title}</h1>
                        <p className="font_18 mb-3 ">{card.description}</p>
                        <button className="btn btn_black text-uppercase mt-2">
                          Learn more
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-5 order-lg-2 order-1 ">
                      <div className="wow animate__animated  animate__fadeInRightBig">

                        <img
                          src={card.img}
                          alt={card.title}
                          className="img-fluid hero_swiper_img wow animate__animated animate__fadeIn"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper> */}

            <div className="d-flex justify-content-between align-items-center mt-3">

              <div className="d-block d-md-none">
                <span id="slide-number" className="">
                  <span className="small_heading popular">
                    {formatNumber(currentSlide)}
                  </span>{" "}
                  <span className="font_18 blue_dull">/ </span>
                  <span className="font_14 blue_dull">
                    {formatNumber(cardsData.length)}
                  </span>
                </span>
              </div>
              <div className="d-flex gap-md-3 gap-2 align-items-center">
                <div className="btn_about_prev bg-white border-dark swiper-button-hero-prev"
                  onClick={() => {
                    if (currentSlide != 1) {
                      setCurrentSlide(currentSlide - 1)
                    } else {
                      setCurrentSlide(1)
                    }
                  }
                  }>
                  <img
                    src="assets/images/home/leftarroww.svg"
                    alt="Previous"
                    className="img-fluid"
                  />
                </div>
                <div className="btn_about_next bg-white border-dark swiper-button-hero-next"
                  onClick={() => {
                    if (currentSlide != 2) {
                      setCurrentSlide(currentSlide + 1)
                    } else {
                      setCurrentSlide(2)
                    }
                  }
                  }>
                  <img
                    src="assets/images/home/rightarrow.svg"
                    alt="Next"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
