import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="container-fluid">
        <footer className="tradsify_footer">
          <div className="row gy-lg-4 gy-3 justify-content-between">
            <div className="col-xl-4 col-md-6">
              <Link to={"/"} className="pointer">
                <img
                  src="/assets/images/logo/Tradsify.svg"
                  alt=""
                  className="img-fluid logo_footer"
                />
              </Link>
              <p className="font_14 pt-xl-3 pt-2">
                Upgrade the way you trade with the world's largest retail broker
                and benefit from better-than-market conditions.
              </p>
              <button className="btn btn_black me-2 mt-lg-2 mt-0">
                Subscribe to newsletter
              </button>
            </div>
            <div className="col-xl-4 col-md-6 text-md-end">
              <div className="mb-3 sub_title">Social description</div>
              <ul className="list-unstyled d-flex justify-content-md-end gap-3 mb-4">
                <li className="footer_icon_border d-flex justify-content-center align-items-center">
                  <img src="../assets/images/footer/twitter.svg" className="" />
                </li>
                <li className="footer_icon_border d-flex justify-content-center align-items-center">
                  <img src="../assets/images/footer/discord-white-icon.svg" />
                </li>
                <li className="footer_icon_border d-flex justify-content-center align-items-center">
                  <img src="../assets/images/footer/youtube-app-white-icon.svg" />
                </li>
                <li className="footer_icon_border d-flex justify-content-center align-items-center">
                  <img src="../assets/images/footer/telegram-white-icon.svg" />
                </li>
              </ul>
            </div>
          </div>

          <div className="parent mt-md-5">
            <div className="">
              <div className="sub_title mb-3">Trading</div>
              <ul className="list-unstyled">
                <li className="font_14 mb-2">
                  <Link to="coming-soon" 
                  className="color_black text-decoration-none">
                    All Instruments
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Markets
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Trading Accounts
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Trading Fees
                  </Link>
                </li>
                <li className="font_14">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Support
                  </Link>
                </li>
              </ul>
            </div>
            <div className="">
              <div className="sub_title mb-3">Platforms</div>
              <ul className="list-unstyled">
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Overview
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Tradsify App
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="/metatrader-5"
                    className="color_black text-decoration-none"
                  >
                    MetaTrader 5
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    TradingView
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    ProTrader
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Copy Trading{" "}
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Demo Trading
                  </Link>
                </li>
              </ul>
            </div>
            <div className="">
              <div className="sub_title mb-3">Academy</div>
              <ul className="list-unstyled">
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    All Articles
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Courses
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    eBooks
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Terminology
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Live
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    The Tradsify View
                  </Link>
                </li>
                
              </ul>
            </div>
            <div className="">
              <div className="sub_title mb-3">Analysis</div>
              <ul className="list-unstyled">
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Overview
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Economic Calendar
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Market Analysis
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Client Sentiment
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Forex VPS
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Trading Signal
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Notifications
                  </Link>
                </li>
              </ul>
            </div>
            <div className="">
              <div className="sub_title mb-3">Promotions</div>
              <ul className="list-unstyled">
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Deposit Bonus
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Refer a Friend
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Tradsify Rewards
                  </Link>
                </li>
                {/* <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                   Funds Growth
                  </Link>
                </li> */}
                {/* <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Christmas Cashback <br className="d-md-block d-none" /> Galore
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className="">
              <div className="sub_title mb-3">About</div>
              <ul className="list-unstyled">
                <li className="font_14 mb-2">
                  <Link
                    to="/coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Why Tradsify?
                  </Link>
                </li>
                {/* <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Fund Insurance
                  </Link>
                </li> */}
                {/* <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    ESG
                  </Link>
                </li> */}
                {/* <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Press Releases
                  </Link>
                </li> */}
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Our Trading Servers
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Reviews
                  </Link>
                </li>
                {/* <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Media Centre
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Our Partners
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Sponsorship
                  </Link>
                </li> */}
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Help Center
                  </Link>
                </li>
                <li className="font_14 mb-2">
                  <Link
                    to="/contact"
                    className="color_black text-decoration-none"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="">
              <div className="sub_title mb-3">Partnership</div>
              <ul className="list-unstyled">
                <li className="font_14 mb-2">
                  <Link
                    to="coming-soon"
                    className="color_black text-decoration-none"
                  >
                    Introducing Broker
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 mt-lg-4 mt-2">
            {/* <p className="text-start font_14 border-bottom pb-3 border-dark mb-0">
                tradesify Markets Incorporated employs Transport Layer Security (TLS) encryption to protect all transaction data transmitted through the platform. This industry-standard encryption protocol ensures the confidentiality and integrity of your sensitive information during transmission, safeguarding it against unauthorised access and interception.
              </p>  */}

            <div className="font_15">
              <p>
                <strong>Tradsify Ltd</strong>
              </p>
              <p>
                <strong>Registration number</strong> : 2024-00580
              </p>
              <p>
                <b>Registered office address:</b> Ground Floor, The Sotheby
                Building, Rodney Village, Rodney Bay, Gros-Islet, Saint Lucia.
              </p>

              <p>
                For Risk Disclosure and information{" "}
                <Link to="/risk-disclosure-and-warnings-notice">
                  visit here
                </Link>
              </p>
              <p>
                <b>Risk Disclaimer:</b> An investment in derivatives may mean
                investors may lose an amount even greater than their original
                investment. Anyone wishing to invest in any of the products
                mentioned in www.tradsify.com should seek their own financial or
                professional advice. Trading of securities, forex, stock market,
                commodities, options and futures may not be suitable for
                everyone and involves the risk of losing part or all of your
                money. Trading in the financial markets has large potential
                rewards, but also large potential risk. You must be aware of the
                risks and be willing to accept them in order to invest in the
                markets. Don’t invest and trade with money which you can’t
                afford to lose. Forex Trading are not allowed in some countries,
                before investing your money, make sure whether your country is
                allowing this or not.
              </p>
              <p>
                You are strongly advised to obtain independent financial, legal
                and tax advice before proceeding with any currency or spot
                metals trade. Nothing in this site should be read or construed
                as constituting advice on the part of tradsify or any of its
                affiliates, directors, officers or employees.
              </p>
              <p>
                <b>Restricted Regions:</b>tradsify does not provide services for
                citizens/residents of the United States, Cuba, Iraq, Myanmar,
                North Korea, Sudan. The services of tradsify are not intended
                for distribution to, or use by, any person in any country or
                jurisdiction where such distribution or use would be contrary to
                local law or regulation.
              </p>
              <span>OR</span>
              <p>
                Information on this site is not directed at residents in any
                country or jurisdiction where such distribution or use would be
                contrary to local law or regulation.
              </p>
              <p>
                We urge all users to undertake their own research and due
                diligence before making any investment decisions.
              </p>
              <p className="border-bottom pb-3 border-dark">
                Thank you for choosing tradsify. We prioritize the safety and
                well-being of our community and encourage informed and
                responsible trading.
              </p>
            </div>
          </div>
          <div className="col-lg-7 copyright_font_style">
            Copyright © 2024 tradsify ,All Rights Reserved.
          </div>
          <div className="col-lg-5 text-lg-end ">
            <Link
              to="/privacy-policy"
              className="text-decoration-none mb-0 font_12 text-black"
            >
              Privacy Policy
            </Link>
            <span className="mx-2 ">|</span>
            <Link className="text-decoration-none mb-0 font_12 text-black">
              Terms
            </Link>
          </div>
        </footer>
      </div>
    </>
  );
}
