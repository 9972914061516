import React, { useState, useEffect } from 'react';

const NewYearPopup = () => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        // if (!sessionStorage.getItem('newYearPopupSeen')) {
        //     setIsVisible(true);
        // }
    }, []);

    const handleClosePopup = () => {
        setIsVisible(false);
        // sessionStorage.setItem('newYearPopupSeen', 'true');
    };
    return (
        <>
            {isVisible && (
                <div className="popup-overlay">
                    <div className="popup position-relative">
                        <img src="assets/images/newyear/new2025.jpg" alt="" className='img-fluid rounded' />
                        <button onClick={handleClosePopup} className='close_btn p-1 border-0 bg-light rounded-2'>
                            <img src="assets/images/newyear/close.png" alt="" className='w-75' />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default NewYearPopup;
