import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPasssword from "./pages/ResetPasssword";
import SendMail from "./pages/SendMail";
import About from "./pages/About";
import Service from "./pages/Service";
import Amlpolicy from "./pages/Amlpolicy";
import Metatrader5 from "./pages/Metatrader5";
import Metatrader4 from "./pages/Metatrader4";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Guide from "./pages/Guide";
import Riskdisclosureandwarningsnotice from "./pages/Riskdisclosureandwarningsnotice.jsx";
import CommingSoon from "./pages/CommingSoon.jsx";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/services" element={<Service />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        {/* <Route path="/login" element={<Login />} /> */}
        {/* <Route path="/register" element={<Signup />} /> */}
        {/* <Route path="/forgot" element={<ForgetPassword />} /> */}
        {/* <Route path="/otp" element={<SendMail />} /> */}
        {/* <Route path="/reset" element={<ResetPasssword />} /> */}
        <Route path="/aml-policy" element={<Amlpolicy />} />
        <Route path="/metatrader-5" element={<Metatrader5 />} />
        <Route path="/metatrader-4" element={<Metatrader4 />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/guide" element={<Guide />} />
        <Route path="/risk-disclosure-and-warnings-notice" element={<Riskdisclosureandwarningsnotice/>}/>
        <Route path="/coming-soon" element={<CommingSoon/>}/>


      </Routes>
    </BrowserRouter>
  );
}

export default App;
