import { Link } from "react-router-dom";

export default function Get_Apps() {
    return (
        <>
            <div className="section_space">
                <div className="container">
                    <div className="row align-items-center gy-lg-0 gy-4 bg-light rounded-4 p-5">
                        <div className="col-lg-6 col-md-6 col-12 order-lg-1 order-2">
                            <div className="my-md-4">
                                <h5 className="medium_heading mb-md-4 mb-2">
                                    Download  MetaTrader 5 (MT5)
                                </h5>
                                <p className="font_15">
                                    Gain an edge with lightning-fast execution,
                                    powerful charting, and access to professional signals.
                                </p>
                                <Link target="_blank" to="https://download.mql5.com/cdn/mobile/mt5/android?server=Tradsify-Live">
                                <img src="assets/images/blog/google-play-row.svg" alt="" className="img-fluid " />
                                </Link>
                                <Link target="_blank" to="https://download.mql5.com/cdn/mobile/mt5/ios?server=Tradsify-Live">
                                <img src="assets/images/blog/apple-store-row.svg" alt="" className="img-fluid ms-3 " />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 order-lg-1 order-2">
                            <img src="assets/images/blog/trading-platform-list5.webp" alt="" className="img-fluid" />
                         </div>
                    </div>
                </div>
            </div>
        </>
    );
}