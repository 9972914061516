import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";
export default function About() {
  return (
    <>
      <ScrollToTop>
        <Header />
        <section className="section_space about_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h1 className="main_heading  z-4 position-relative">
                  Get to Know tradsify
                </h1>
                <p className="font_15 position-relative ">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section_space pt-0">
          <div className="container">
            <div className="row row gx-xl-5 gx-md-4 gx-3 mt-xl-0 mt-sm-1">
              <div className="col-lg-3 col-6">
                <div className="border-image hover_gradgent">
                  <h3 className="sami_medium_heading  mb-xl-4">
                    12Years+
                  </h3>
                  <p className="font_20 mb-0">Worldwide</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="border-image hover_gradgent">
                  <h3 className="sami_medium_heading  mb-xl-4">70+</h3>
                  <p className="font_20 mb-0">Awards and counting</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="border-image hover_gradgent">
                  <h3 className="sami_medium_heading  mb-xl-4">42 mln+</h3>
                  <p className="font_20 mb-0">Trading accounts opened</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="border-image hover_gradgent">
                  <h3 className="sami_medium_heading  mb-xl-4">180+</h3>
                  <p className="font_20 mb-0">Countries across the world</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_space pt-0">
          <div className="container">
            <div className="row g-lg-5 g-4 align-items-center">
              <div className="col-lg-6">
                <img
                  src="/assets/images/about/who.png"
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
              <div className="col-lg-6">
                <h2 className="sub_heading">Who We Are</h2>
                <p className="font_15">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard .
                </p>
                <p className="font_15">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an
                  unknown printer took a galley of type and scrambled it to make
                  a type specimen book.
                </p>
                <p className="font_15">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section_space ">
          <div className="container">
            <div>
              <h4 className="text-center sub_heading mb-xl-5
               mb-3">
                Tradsify Values
              </h4>
            </div>
            <div className="row g-md-4 g-3">
              <div className="col-lg-4">
                <div className="about_value_card">
                  <h6 className="sub_para_title">User profit First</h6>
                  <p className="font_15">
                    We prioritize our users in everything we do, enabling them
                    to constantly stay ahead and do more .
                  </p>

                  <img
                    src="/assets/images/about/wallet.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="about_value_card">
                  <h6 className="sub_para_title">Reliable</h6>
                  <p className="font_15">
                    We are committed to providing a secure platform with 100%
                    user asset protection and 24/7 global support.
                  </p>
                  <img
                    src="/assets/images/about/protected.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="about_value_card">
                  <h6 className="sub_para_title">Diversified</h6>
                  <p className="font_15">
                    We provide a comprehensive range of products and services to
                    users of all levels, from beginners to professionals.
                  </p>
                  <img
                    src="/assets/images/about/pie_chart.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_space">
          <div className="container">
            <div className="row gy-3 gx-xl-3 gx-md-3 align-items-center">
              <div className="col-lg-5">
                <h2 className="sub_heading mb-xl-4 mt-lg-5">
                  Trade With An Advantage
                </h2>
                <p className="font_15 pe-lg-5">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
                <p className="font_15 pe-lg-5 mb-0">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
                {/* <button className="btn btn_black mt-3">View More</button> */}
              </div>
              <div className="col-lg-3 pt-xl-5 mt-lg-5 offset-lg-1">
                <div className="bg_grad we_do_card mb-3 mt-lg-5 mt-2 text-light">
                  <img
                    src="/assets/images/about/feature-2-icon-1.svg"
                    alt=""
                    className="img-fluid mb-xl-5 mb-lg-2 pt-xl-3"
                  />
                  <h6 className="font_20 fw-bold mt-xl-4 mt-3 w-bold ">
                    Tight spreads
                  </h6>
                  <p className="font_15 mb-0">Lorem Ipsum is simply </p>
                </div>
                <div className="card we_do_card text-light">
                  <img
                    src="/assets/images/about/feature-2-icon-2.svg"
                    alt=""
                    className="img-fluid mb-xl-5 mb-lg-2 pt-xl-3"
                  />
                  <h6 className="font_20 fw-bold mt-xl-4 mt-3">
                    No commissions
                  </h6>
                  <p className="font_15 mb-0">Lorem Ipsum is Lorem Ipsum is</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="bg_grad we_do_card text-light mb-3">
                  <img
                    src="/assets/images/about/feature-2-icon-3.svg"
                    alt=""
                    className="img-fluid mb-xl-5 mb-lg-2 pt-xl-3"
                  />
                  <h6 className="font_20 fw-bold mt-xl-4 mt-3">
                    Fast deposits and withdrawals
                  </h6>
                  <p className="font_15 mb-0">Lorem Ipsum is simply dummy </p>
                </div>
                <div className="card we_do_card text-light">
                  <img
                    src="/assets/images/about/feature-2-icon-4.svg"
                    alt=""
                    className="img-fluid mb-xl-5 mb-lg- mb-2 pt-xl-3"
                  />
                  <h6 className="font_20 fw-bold mt-xl-4 mt-3">
                    Rewarding loyalty program
                  </h6>
                  <p className="font_15 mb-0">
                    Lorem Ipsum is simply dummy tex
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_space">
          <div className="container">
            <div className="row g-md-4 g-3">
              <div className="col-lg-6">
                <div className="why_section_card">
                  <img
                    src="/assets/images/about/mission.png"
                    alt=""
                    className="img-fluid mb-4 img-size"
                  />
                  <h5 className="small_heading">Mission</h5>
                  <p className="mb-0 font_15">
                    We are committed to providing a trustworthy and transparent
                    platform, making accessible and friendly to everyone.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="why_section_card">
                  <img
                    src="/assets/images/about/vision.png"
                    alt=""
                    className="img-fluid mb-3 vision_img"
                  />
                  <h5 className="small_heading">Vision</h5>
                  <p className="mb-0 font_15">
                    We aim to build a gateway for the next billion users.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </ScrollToTop>
    </>
  );
}
